import {Create, required, SimpleForm, TextInput, minLength} from "react-admin";
import ApplicationReferenceInput from "../ApplicationReferenceInput";
import * as React from "react";
import { useLocation } from 'react-router';

const RoleCreate = props => {
    // Read the post_id from the location
    const location = useLocation();
    let application_id =
        location.state && location.state.record
            ? location.state.record.application_id
            : undefined;

    if(application_id === undefined) {
        application_id = location.pathname;
        application_id = application_id.replace('/role/create/', '');
        application_id = application_id.replace('/', '');
    }

    //console.log('Role::create');
    const redirect = application_id ? `/application/${application_id}/1` : false;

    return (
        <Create {...props}>
            <SimpleForm
                redirect={redirect}
            >
                <ApplicationReferenceInput
                    source="application"
                    reference="application"
                    validate={required()}
                    defaultValue={application_id}
                    perPage={10}
                    fullWidth
                    required
                />
                <TextInput source="role" fullWidth required validate={minLength(5)} />
            </SimpleForm>
        </Create>
    );
};

export default RoleCreate;