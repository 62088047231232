const numberPrefs = [
    'page_type_permissions',
    'default_prompt_group',

    // RS
    'q1',
    'q2',
    'q3',
    'q4',
    'process',
    'assessment_sales',
    'assessment_mkt',
    'parent_id'
];

const jsonPrefs = [
    'blackouts',
    'custom_progress_bar',
    'enable_or_hide_toolbar_icon',
    'profile_settings'
]

const colorPrefs = [
    'color_btn_post',
    'color_btn_post_background',
    'color_btn_post_border',
    'color_btn_post_hover',
    'color_course_arrows',
    'color_course_name',
    'color_flashcards_progressbar',
    'color_header_background',
    'color_header_border',
    'color_header_drop_down_background',
    'color_header_drop_down_background_hover',
    'color_header_drop_down_text',
    'color_header_drop_down_text_hover',
    'color_header_menu_text_hover',
    'color_header_text',
    'color_header_text_hover',
    'color_header_text_hover_background',
    'color_j1_dashboard_left_circle',
    'color_j1_dashboard_left_text',
    'color_j1_dashboard_right_buttons_background',
    'color_j1_dashboard_right_buttons_border',
    'color_j1_dashboard_right_buttons_color',
    'color_j1_dashboard_right_text',
    'color_j1_practice_button_background',
    'color_j1_practice_button_background_hover',
    'color_j1_practice_button_border',
    'color_j1_practice_button_border_hover',
    'color_j1_practice_button_font_color',
    'color_j1_practice_button_font_color_hover',
    'color_j1_start_button_background',
    'color_j1_start_button_background_hover',
    'color_j1_start_button_border',
    'color_j1_start_button_border_hover',
    'color_j1_start_button_font_color',
    'color_j1_start_button_font_color_hover',
    'color_left_menu_background',
    'color_left_menu_checkmark',
    'color_left_menu_completion_box',
    'color_left_menu_completion_box_selected',
    'color_left_menu_grades_item',
    'color_left_menu_grades_item_background',
    'color_left_menu_item',
    'color_left_menu_item_background',
    'color_left_menu_selected_grades_item',
    'color_left_menu_selected_grades_item_background',
    'color_left_menu_selected_item',
    'color_left_menu_selected_item_background',
    'color_left_menu_selected_tab_background',
    'color_left_menu_selected_tab_border',
    'color_left_menu_selected_tab_text',
    'color_left_menu_tab_background',
    'color_left_menu_tab_border',
    'color_left_menu_tab_text',
    'color_nav_progress_circle',
    'color_right_panels_header',
    'color_right_panels_header_background',
    'color_right_panels_selected_tab',
    'color_right_panels_selected_tab_background',
    'color_right_panels_tab',
    'color_right_panels_tab_background',
    'color_test_dashboard_background',
    'color_test_dashboard_font',
    'color_topbar_badge',
    'color_top_icons',
    'color_top_icons_hover'
];

const htmlPrefs = [
    'email_header_template',
    'email_footer_template',
    'custom_course_css',
    'custom_course_header'
];

const booleanPrefs = [
    'white_label',
    'use_splash',
    'show_final_grade',
    'can_delete_posts',
    'save_deleted_posts',
    'teacher_can_edit_classes',
    'calculate_progress',
    'can_edit_profile',
    'hide_grades_page',
    'disallow_email',
    'disable_account_emails',
    'hide_actual_score',
    'hide_grade_clompleted_word',
    'hide_expected_by_today',
    'hide_completed_so_far',
    'hide_progress',
    'hide_projected_end_date',
    'hide_expected_end_date',
    'show_gender',
    'show_site',
    'hide_all_messages',
    'enable_reports',
    'enable_fix_quizzes',
    'enable_attendance',
    'category_dashboard',
    'enable_password_expiration',
    'show_resubmit_button',
    'show_password_manager',
    'use_custom_progress_bar',
    'show_overall_score',
    'use_alert_splash',
    'enable_chat_app',
    'enable_attendance_warning',
    'enable_gradebook_categories',
    'enable_credited_assignments',
    'hide_eng_menu',
    'show_user_profile',
    'enable_post_email_notifications',
    'show_breadcrumbs',
    'show_unit_tabs',
    'use_powerschool',
    'compliance_dashboard',
    'use_my_alerts',
    'use_reports',
    'use_j1_dashboard',
    'use_e3pt_dashboard',
    'enable_certificate',
    'ceeb',
    'allow_users_to_log_in_as_others',
    'show_attendance_hours_reported',
    'enable_navigation',
    'enable_knowledgebase',

    // Riverside from riverside_organizations

    // organization_preferences
    'enable_report_activity',
    'enable_report_attendance',
    'enable_report_grades',
    'enable_report_grading_queue',
    'enable_report_progress',
    'enable_report_time_spent',
    'show_departments',
    'show_terms',
    'submit_file_automatically',
    'use_default_j1_dashboard',
    'use_home',
    'use_webrtc',
    'allow_organization_and_site_admins_to_log_in_as_others',
    'allow_teachers_to_log_in_as_others',
    'enable_report_different_pages',
    'enable_report_not_logged_in',
    'enable_report_posts_per_student',
    'use_new_j1_dashboard'
];


export { numberPrefs, jsonPrefs, colorPrefs, htmlPrefs, booleanPrefs };