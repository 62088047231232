import {Create, SimpleForm, TextInput, regex, required, minLength} from "react-admin";
import * as React from "react";
import FundList from "./FundList";

const FundCreate = props => {
    return (
        <Create
            resource="fund"
            hasList={FundList}
            {...props}>
            <SimpleForm redirect="/fund">
                <TextInput name="id" label="Id"
                           fullWidth validate={
                               [
                                   regex(/^[_a-z0-9]+$/, 'Should contain only latin lowercase characters, numbers and underscores'),
                                   minLength(4)
                               ]
                } required/>
                <TextInput source="name" fullWidth required />
            </SimpleForm>
        </Create>
    );
};

export default FundCreate;