import {
    BooleanField,
    BooleanInput,
    Datagrid,
    EditButton,
    Filter,
    List,
    SearchInput,
    TextField,
    TextInput
} from "react-admin";
import * as React from "react";

const OrganizationsFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn/>
        <TextInput label="Name" source="name" defaultValue="" />
        <TextInput label="Domain" source="domain" defaultValue=""/>
        <TextInput label="Fund" source="fund" defaultValue=""/>
        <TextInput label="Application" source="application" defaultValue=""/>
        <TextInput label="Import Id" source="import_id" defaultValue=""/>
        <BooleanInput label="Active" source="active" defaultValue="" />
    </Filter>
);

const OrganizationList = props => (
    <List {...props} title="List of Organizations" filters={<OrganizationsFilter />} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <TextField source="id" sortable={true}/>
            <TextField source="import_id" label="Import Id" sortable={true}/>
            <TextField source="description.domain" label="Domain" sortable={true}/>
            <TextField source="description.name" label="Name" sortable={true}/>
            <TextField source="fund" label="Fund" sortable={true}/>
            <TextField source="application" label="Application" sortable={true}/>
            <BooleanField label="Active"
                          source="active"
                          sortable={true}
            />
            <EditButton basePath="/organization" />
        </Datagrid>
    </List>
);

export default OrganizationList;