import {Create, required, SimpleForm, TextInput, SelectInput, minLength} from "react-admin";
import UserReferenceInput from "../UserReferenceInput";
import * as React from "react";
import { useLocation } from 'react-router';

const GlobalRoleCreate = props => {
    // Read the post_id from the location
    const location = useLocation();
    let user_id =
        location.state && location.state.record
            ? location.state.record.user_id
            : undefined;

    if(user_id === undefined) {
        user_id = location.pathname;
        user_id = user_id.replace('/user_global_role/create/', '');
        user_id = user_id.replace('/', '');
    }

    //console.log('Role::create');
    const redirect = user_id ? `/user/${user_id}/1` : false;

    return (
        <Create {...props} title="Assign new System Role for User">
            <SimpleForm
                redirect={redirect}
            >
                <UserReferenceInput
                    source="user"
                    reference="user"
                    validate={required()}
                    defaultValue={user_id}
                    perPage={10}
                    fullWidth
                    required
                />
                <SelectInput fullWidth source="role" choices={[
                    { id: 'ROLE_UNPRIVILEGED', name: 'General User' },
                    { id: 'ROLE_SUPER_ADMIN', name: 'Super Admin' }
                ]} />
            </SimpleForm>
        </Create>
    );
};

export default GlobalRoleCreate;