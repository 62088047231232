import {
    BooleanInput,
    Datagrid,
    Edit,
    EditButton,
    TextField,
    TextInput,
    List,
    Tab,
    SimpleForm,
    TabbedShowLayout,
    BooleanField, Filter, SearchInput
} from "react-admin";
import * as React from "react";

import AddRoleButton from "../role/AddRoleButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddOrganizationButton from "../organization/AddOrganizationButton";

const OrganizationsFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn/>
        <TextInput label="Name" source="name" defaultValue="" />
        <TextInput label="Domain" source="domain" defaultValue=""/>
        <TextInput label="Fund" source="fund" defaultValue=""/>
        <TextInput label="Import Id" source="import_id" defaultValue=""/>
        <BooleanInput label="Active" source="active" defaultValue="" />
    </Filter>
);

const ApplicationEdit = props => {

    const appPath = '/application/' + props.id;
    const basePath = '/application/' + props.id + '/role';
    const userPath = '/application/' + props.id + '/user_role';
    const organizationPath = '/organization';

    return (
        <TabbedShowLayout resource="application" >
            <Tab label="General Info" resource="application">
                <Edit id={props.id} resource="application" undoable={false} basePath={appPath} {...props}>
                    <SimpleForm redirect='/application'>
                        <TextInput disabled label="Id" source="id" fullWidth/>
                        <TextInput disabled label="Secret Key" source="secret" fullWidth/>
                        <TextInput label="Name" source="name" defaultValue="" fullWidth/>
                        <TextInput label="Domain" source="domain" defaultValue="" fullWidth/>
                        <TextInput label="Return To URI" source="return_to" fullWidth/>
                        <TextInput label="Login URI" source="login_url" fullWidth/>
                        <TextInput label="Integration URI" source="integration_url" fullWidth/>
                        <BooleanInput label="Active" source="active"/>
                    </SimpleForm>
                </Edit>
            </Tab>
            <Tab label="Roles" resource="application_role">
                <List resource="application_role"
                      hasList={false}
                      actions={null}
                      basePath={basePath} hasCreate={false} hasEdit={false} hasShow={false} exporter={false}
                      filter={{application_id: props.id, application_role: true}}
                >
                        <Datagrid>
                            <TextField source="id" sortable={false}/>
                            <TextField source="role" sortable={false}/>
                            <EditButton basePath={userPath} label="VIEW USERS" icon={(<VisibilityIcon />)} />
                            <EditButton basePath={basePath} />
                        </Datagrid>
                </List>
                <AddRoleButton id={props.id}/>
            </Tab>
            <Tab label="Organizations">
                <List
                      resource="organization"
                      hasList={false}
                      filters={<OrganizationsFilter />} bulkActionButtons={false}
                      basePath={basePath} hasCreate={false} hasEdit={false} hasShow={false} exporter={false}
                      filter={{application_id: props.id, application_organization: true}}
                >
                    <Datagrid>
                        <TextField source="id" sortable={true}/>
                        <TextField source="import_id" label="Import Id" sortable={true}/>
                        <TextField source="description.domain" label="Domain" sortable={true}/>
                        <TextField source="description.name" label="Name" sortable={true}/>
                        <TextField source="fund" label="Fund" sortable={true}/>
                        <BooleanField label="Active"
                                      source="active"
                                      sortable={true}
                        />
                        <EditButton basePath={organizationPath} />
                    </Datagrid>
                </List>
                <AddOrganizationButton id={props.id}/>
            </Tab>
        </TabbedShowLayout>
    )
};

export default ApplicationEdit;