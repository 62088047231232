import {
    Edit,
    Toolbar,
    SaveButton,
    DeleteButton,
    required,
    SimpleForm,
    TextInput,
    minLength,
    Datagrid, TextField, EmailField, EditButton, List, Filter, SearchInput
} from "react-admin";
import * as React from "react";


const UserFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <TextInput label="Email" source="email" defaultValue="" />
        <TextInput label="First Name" source="first_name" defaultValue="" />
        <TextInput label="Last Name" source="last_name" defaultValue="" />
    </Filter>
);


const UserRoleEdit = props => {
    // Read the post_id from the location
    const application_id = props.match.params.application_id;
    const id = props.match.params.id;

    //console.log('Role::create');
    const redirect = `/application/${application_id}`;
    const basePath = props.location.pathname;

    return (
        <List {...props}
              hasShow={false}
              hasEdit={false}
              hasCreate={false}
              basePath={basePath}
              hasList={false}
              resource="app_user_role"
              filters={<UserFilter />}
              title="List of Users with given Role"
              bulkActionButtons={false}
        >
            <Datagrid>
                <TextField source="id" />
                <EmailField source="email" />
                <TextField source="first_name"  />
                <TextField source="last_name" />
                <DeleteButton label="Demote" />
            </Datagrid>
        </List>
    );
};

export default UserRoleEdit;