import React from 'react';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
    button: {
        marginTop: '10px'
    }
};


const AddOrganizationPreferenceButton = ({ classes, record, id }) => (
    <Button
        variant="contained"
        component={Link}
        to={`/preference/create/${id}/`}
        label="Add Organization Preference"
        className={classes.button}
    >
        <AddCircleOutlineIcon />
    </Button>
);

export default withStyles(styles)(AddOrganizationPreferenceButton);