import ApiProvider from './apiProvier';

let api = new ApiProvider();

export default {

    getList:  (resource, params) =>  {

        //('dataProvider: getList');
        //console.log(resource);

        const token = localStorage.getItem('token');

        if(!token) {
            return Promise.reject('Your session has expired. Please, login');
        }

        let apiUri = '/admin/' + resource;

        if(resource === 'app_user_role') {

            let hash = document.location.hash;
            hash = hash.split('?');
            hash = hash[0];
            hash = hash.replace('#', '');
            apiUri = '/admin' + hash;
        }

        if(resource === 'application_organization') {
            if(typeof(params.filter['organization_preferences']) !== 'undefined') {
                resource = 'organization_preferences';
            }
        }

        /** Ugly workaround as List does not properly understands the configured resource  **/
        if(resource === 'application') {
            if(typeof(params.filter['application_role']) !== 'undefined') {
                resource = 'application_role';
            }

            if(typeof(params.filter['application_organization']) !== 'undefined') {
                resource = 'application_organization';
            }
        }

        if(resource === 'fund') {
            console.log(params.filter);
            if(typeof(params.filter['fund_organizations']) !== 'undefined') {
                resource = 'organization';
                apiUri = '/admin/organization/';
            }
        }

        if(resource === 'application_role') {
            apiUri = '/admin/application/' + params.filter['application_id'] +'/role';

        }

        if(resource === 'application_organization') {
            apiUri = '/admin/application/' + params.filter['application_id'] +'/organization';
        }

        if(resource === 'organization_preferences') {
            apiUri = '/admin/organization/' + params.filter['organization_id'] +'/preference';
        }

        if(resource === 'fund') {
            apiUri = '/admin/fund';
        }

        if(resource === 'country') {
            apiUri = '/country';
        }

        if(resource === 'language') {
            apiUri = '/language';
        }


        return new Promise(function(resolve, reject){

            api.get(apiUri + '/?'
                + 'offset=' + ( params.pagination.page - 1 ) * params.pagination.perPage
                + '&limit=' + params.pagination.perPage
                + '&sort=' + encodeURIComponent(JSON.stringify(params.sort))
                + '&filter=' + encodeURIComponent(JSON.stringify(params.filter))
                , null, token)
                .then(response => {

                    if(typeof(response) === 'undefined') {
                        return reject('Please, try again later');
                    }

                    if(typeof(response.data) === 'undefined') {
                        return reject('Please, try again later');
                    }

                    let rs = {};

                    rs.data = response.data;
                    rs.total = response.pagination.total;

                    return resolve(rs);

                }, error => {
                    return reject(error);
                });
        });
    },

    getOne:  (resource, params) =>  {

        //console.log('dataProvider: getOne');
        //console.log(params);

        let apiUri = '/admin/' + resource + '/' + params.id + '/';

        if(resource === 'application_organization') {
            let hash = document.location.hash;
            hash = hash.split('?');
            hash = hash[0];
            hash = hash.replace('#', '');
            hash = hash.split('/');
            hash = hash[hash.length-1];

            apiUri = '/admin/organization/' + hash + '/';
        }

        if(resource === 'organization_preferences')
        {
            //apiUri = '/admin/organization/' + params.id.organization_id + '/preference/' + params.id.id + '/';
            //apiUri = '/admin/organization/049b8c32-8142-4dca-a238-ea351f2a6c9d/preference/' + params.id + '/';
            let hash = document.location.hash;
            hash = hash.replace('#', '');
            apiUri = '/admin' + hash + '/';
        }

        if(resource === 'role') {
            let hash = document.location.hash;
            hash = hash.replace('#/application/', '');
            let application_id = hash.replace('/role/' + params.id, '');

            apiUri = '/admin/application/' + application_id + '/role/' + params.id + '/';
        }

        const token = localStorage.getItem('token');

        if(!token) {
            return Promise.reject('Your session has expired. Please, login');
        }

        return new Promise(function(resolve, reject){

            api.get(apiUri, null, token)
                .then(response => {

                    if(typeof(response.id) === 'undefined') {
                        return reject('Please, try again later');
                    }

                    return resolve({data: response});

                }, error => {
                    return reject(error);
                });
        });
    },

    getMany:  (resource, params) =>  {

        //console.log('dataProvider: getMany');

        //console.log(resource);
        //console.log(params);

        const token = localStorage.getItem('token');

        if(!token) {
            return Promise.reject('Your session has expired. Please, login');
        }

        let apiUri = '/admin/' + resource;

        if(resource === 'application_role') {
            apiUri = '/admin/application_role';
        }

        if(resource === 'country') {
            apiUri = '/country';
        }

        if(resource === 'language') {
            apiUri = '/language';
        }

        return new Promise(function(resolve, reject){

            api.get(apiUri + '/?'
                + 'offset=0'
                + '&limit=' + params.ids.length
                + '&filter=' + encodeURIComponent(JSON.stringify(params))
                , null, token)
                .then(response => {

                    if(typeof(response) === 'undefined') {
                        return reject('Please, try again later');
                    }

                    if(typeof(response.data) === 'undefined') {
                        return reject('Please, try again later');
                    }

                    let rs = {};

                    rs.data = response.data;
                    rs.total = response.pagination.total;

                    return resolve(rs);

                }, error => {
                    return reject(error);
                });
        });
    },

    getManyReference:  (resource, params) =>  {

        let apiUri = '/admin/' + params.target + '/' + params.id + '/' + resource + '/';

        //console.log('dataProvider: getManyReference');
        //console.log(resource);
        //console.log(params);

        if(resource === 'user_global_role')
        {
            apiUri = '/admin/user/' + params.id + '/role/';
        }

        if(resource === 'organization_preferences')
        {
            apiUri = '/admin/organization/' + params.id.organization_id + '/role/' + params.id.id;
        }

        if(resource === 'user_application_role')
        {
            apiUri = '/admin/user/' + params.id + '/application_role/';
        }

        //console.log(resource);
        //console.log(params);

        const token = localStorage.getItem('token');

        if(!token) {
            return Promise.reject('Your session has expired. Please, login');
        }

        return new Promise(function(resolve, reject){

            api.get(apiUri
                + '?'
                + 'offset=' + ( params.pagination.page - 1 ) * params.pagination.perPage
                + '&limit=' + params.pagination.perPage
                + '&sort=' + encodeURIComponent(JSON.stringify(params.sort))
                + '&filter=' + encodeURIComponent(JSON.stringify(params.filter))
                , null, token)
                .then(response => {

                    if(typeof(response.data) === 'undefined') {
                        return reject('Please, try again later');
                    }

                    let rs = {};

                    rs.data = response.data;
                    rs.total = response.pagination.total;

                    return resolve(rs);

                }, error => {
                    return reject(error);
                });
        });
    },

    create:  (resource, params) =>  {
        const token = localStorage.getItem('token');

        if(!token) {
            return Promise.reject('Your session has expired. Please, login');
        }

        let apiUri = '/admin/' + resource + '/';

        if(resource === 'role') {
            if(typeof(params.data.application) !== 'undefined') {
                apiUri = '/admin/application/' + params.data.application +'/' + resource + '/';
            }
        }

        if(resource === 'user_global_role')
        {
            apiUri = '/admin/user/' + params.data.user + '/role/';
        }

        if(resource === 'user_application_role')
        {
            apiUri = '/admin/user/' + params.data.user + '/application/' + params.data.application + '/role/';
        }

        if(resource === 'organization_preferences')
        {
            let hash = document.location.hash;
            hash = hash.replace('#', '');
            hash = hash.replace('/preference/create', '');
            apiUri = '/admin/organization' + hash + 'preference/' + params.data.id + '/';
        }


        return new Promise(function(resolve, reject){

            api.post(apiUri, params.data, token)
                .then(response => {

                    if(typeof(response.id) === 'undefined') {
                        return reject('Please, try again later');
                    }

                    let rs = {};

                    rs.data = response;

                    return resolve(rs);

                }, error => {
                    return reject(error);
                });
        });
    },

    update:  (resource, params) =>  {
        const token = localStorage.getItem('token');

        if(!token) {
            return Promise.reject('Your session has expired. Please, login');
        }

        let apiMethod = '/admin/' + resource + '/' + params.id + '/';

        if(resource === 'role') {
            let hash = document.location.hash;
            hash = hash.replace('#/application/', '');
            let application_id = hash.replace('/role/' + params.id, '');

            apiMethod = '/admin/application/' + application_id + '/role/' + params.id + '/';
        }

        let requestData = params.data;

        if(resource === 'organization_preferences')
        {
            //apiUri = '/admin/organization/' + params.id.organization_id + '/preference/' + params.id.id + '/';
            //apiUri = '/admin/organization/049b8c32-8142-4dca-a238-ea351f2a6c9d/preference/' + params.id + '/';
            let hash = document.location.hash;
            hash = hash.replace('#', '');
            apiMethod = '/admin' + hash + '/';
        }

        if(resource === 'user') {
            requestData = {};

            requestData.email = params.data.email;
            requestData.first_name = params.data.full_name.first_name;
            requestData.last_name = params.data.full_name.last_name;
            requestData.gender = parseInt(params.data.gender);
            requestData.language = params.data.language;

            if(params.data.date_of_birth) {
                requestData.date_of_birth = params.data.date_of_birth.split('T')[0];
            }

            requestData.phone = params.data.phone;
            requestData.address = params.data.location.address;
            requestData.city = params.data.location.city;
            requestData.state = params.data.location.state;
            requestData.country = params.data.country;
            requestData.active = params.data.active;

            if(params.data.password !== '') {
                requestData.password = params.data.password;
            }
        }

        if(resource === 'organization' || resource === 'application_organization') {
            apiMethod = '/admin/organization/' + params.id + '/';

            requestData = {};

            requestData.name = params.data.description.name;
            requestData.domain = params.data.description.domain;
            requestData.contact_email = params.data.contact.email;
            requestData.contact_phone = params.data.contact.phone;
            requestData.contact_full_name = params.data.contact.full_name;
            requestData.active = params.data.active;
            requestData.fund_id = params.data.fund.id;
        }

        return new Promise(function(resolve, reject){

            api.patch(apiMethod, requestData, token)
                .then(response => {

                    if(typeof(response.id) === 'undefined') {
                        return reject('Please, try again later');
                    }

                    let rs = {};

                    rs.data = response;

                    return resolve(rs);

                }, error => {
                    return reject(error);
                });
        });
    },

    updateMany:  ( resource, params) =>  {
        console.log(resource);
        console.log(params);

        return Promise.resolve();
    },

    delete:  (resource, params ) =>  {
        const token = localStorage.getItem('token');

        if(!token) {
            return Promise.reject('Your session has expired. Please, login');
        }

        let apiUri = '/admin/' + resource + '/' + params.id + '/';

        if(resource === 'app_user_role') {
            let hash = document.location.hash;
            hash = hash.replace('#', '');
            hash = hash.replace('/user_role/', '/role/');

            apiUri = '/admin' + hash + '/user_role/' + params.id + '/';
        }

        if(resource === 'user_application_role') {
            let hash = document.location.hash;

            hash = hash.replace('#', '');
            hash = hash.substr(0, hash.length - 2);

            apiUri = '/admin/' + hash + '/application_role/' + params.id + '/';
        }

        if(resource === 'organization_preferences')
        {
            //apiUri = '/admin/organization/' + params.id.organization_id + '/preference/' + params.id.id + '/';
            //apiUri = '/admin/organization/049b8c32-8142-4dca-a238-ea351f2a6c9d/preference/' + params.id + '/';
            let hash = document.location.hash;
            hash = hash.replace('#', '');
            apiUri = '/admin' + hash + '/';
        }

        if(resource === 'user_global_role')
        {
            let hash = document.location.hash;

            hash = hash.replace('#', '');
            hash = hash.substr(0, hash.length - 2);

            apiUri = '/admin/' + hash + '/role/' + params.id + '/';
        }

        // console.log(resource);
        // console.log(params);
        //console.log(apiUri);

        if(resource === 'role') {
            let hash = document.location.hash;
            hash = hash.replace('#/application/', '');
            let application_id = hash.replace('/role/' + params.id, '');

            apiUri = '/admin/application/' + application_id + '/role/' + params.id + '/';
        }

        return new Promise(function(resolve, reject){

            api.delete(apiUri, null, token)
                .then(response => {
                    return resolve({data: { id: params.id}});

                }, error => {
                    return reject(error);
                });
        });
    },

    deleteMany:  ({ resourse, params }) =>  {
        console.log(resourse);
        console.log(params);

        return Promise.resolve();
    },
};