// in src/App.js
import * as React from "react";
//import authProvider from './authProvider';
import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import { UserList, UserCreate, UserEdit } from './users';
import { ApplicationList, ApplicationCreate } from './applications';
import ApplicationEdit from './application/ApplicationEdit';
import FundList from './fund/FundList'

import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";
import role from "./role"
import customRoutes from './routes';
import GlobalRoleCreate from "./user/GlobalRoleCreate";
import AddApplicationRoleToUser from "./user/AddApplicationRoleToUser";
import FundCreate from "./fund/FundCreate";
import FundEdit from "./fund/FundEdit";
import OrganizationList from "./organization/OrganizationList";
import OrganizationCreate from "./organization/OrganizationCreate";

const App = () => (
     <Admin dataProvider={dataProvider} authProvider={authProvider} customRoutes={customRoutes}>
            <Resource name="user"  list={UserList} edit={UserEdit} create={UserCreate}/>
            <Resource name="application"  list={ApplicationList} edit={ApplicationEdit} create={ApplicationCreate}/>
            <Resource name="country" />
            <Resource name="language" />
            <Resource name="role" {...role} />
            <Resource name="app_user_role" />
            <Resource name="application_role" />
            <Resource name="application_organization" />
            <Resource name="organization" list={OrganizationList} create={OrganizationCreate}/>
            <Resource name="fund" list={FundList} create={FundCreate} edit={FundEdit}/>
            <Resource name="organization_preferences" />
            <Resource name="user_global_role" create={GlobalRoleCreate}/>
            <Resource name="user_application_role" create={AddApplicationRoleToUser}/>
     </Admin>
);

export default App;
