import {
    BooleanInput,
    Datagrid,
    Edit,
    EditButton,
    TextField,
    TextInput,
    List,
    Tab,
    SimpleForm,
    TabbedShowLayout,
    Toolbar,
    SaveButton,
    Filter, SearchInput, required, email
} from "react-admin";
import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';

import AddOrganizationPreferenceButton from "./AddOrganizationPreferenceButton";
import {Box, Typography} from "@material-ui/core";
import FundReferenceInput from "../FundReferenceInput";

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const OrganizationEditToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton undoable={false} />
    </Toolbar>
);

const PreferencesFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn/>
        <TextInput label="Label" source="label" defaultValue="" />
        <TextInput label="Value" source="value" defaultValue=""/>
    </Filter>
);

const OrganizationEdit = props => {

    const params = props.match.params;

    console.log(params);

    const prefPath = '/organization/' + params.id + '/preference';
    let redirPath = '/organization/';

    if(typeof(params.application_id) !== 'undefined') {
        redirPath = '/application/'+params.application_id+'/2';
    }

    return (
        <TabbedShowLayout resource="application_organization">
            <Tab label="General Info" resource="application_organization">
                <Edit id={params.id} resource="application_organization" {...props} undoable={false}>
                    <SimpleForm redirect={redirPath} toolbar={<OrganizationEditToolbar />}>

                        <Box p="1em" fullWidth>
                            <Box display="flex">
                                <Box flex={2} mr="1em">

                                    <Typography variant="h6" gutterBottom>Basic Properties</Typography>

                                    <Box display="flex">
                                        <Box flex={1} mr="0.5em">
                                            <TextInput label="Name" source="description.name" required fullWidth/>
                                        </Box>
                                        <Box flex={1} ml="0.5em">
                                            <TextInput label="Domain" source="description.domain" fullWidth/>
                                        </Box>
                                    </Box>

                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>Contact</Typography>


                                    <Box display="flex">
                                        <Box flex={1} mr="0.5em">
                                            <TextInput source="contact.email" type="email" label="Email" validate={email()}  fullWidth />
                                        </Box>
                                        <Box flex={1} ml="0.5em">
                                            <TextInput source="contact.full_name" label="Full Name" fullWidth />
                                        </Box>
                                        <Box flex={1} ml="0.5em">
                                            <TextInput source="contact.phone" label="Phone" fullWidth />
                                        </Box>
                                    </Box>
                                </Box>

                                <Box flex={1} ml="1em" fullWidth>
                                    <Typography variant="h6" gutterBottom>Identification</Typography>

                                    <TextInput source="id" label="Id" disabled={true} fullWidth/>

                                    <TextInput source="import_id" label="Import Id" disabled={true} fullWidth/>

                                    <BooleanInput source="active" />

                                    <FundReferenceInput
                                        label="Fund"
                                        allowEmpty={true}
                                        source="fund.id"
                                        reference="fund"
                                        perPage={10}
                                        fullWidth
                                    />
                                </Box>

                            </Box>
                        </Box>

                    </SimpleForm>
                </Edit>
            </Tab>
            <Tab label="Preferences">
                <List id={params.id}
                      resource="organization_preferences"
                      hasList={false}
                      filters={<PreferencesFilter />} bulkActionButtons={false}
                      hasCreate={false} hasEdit={true} hasShow={false} exporter={false}
                      filter={{organization_id: params.id, organization_preferences: true}}
                      sort={{ field: 'id', order: 'ASC'}}
                >
                    <Datagrid>
                        <TextField source="id" label="Id" sortable={false}/>
                        <TextField source="label" label="Label" sortable={true}/>
                        <TextField source="value" label="Value" sortable={true} fullWidth={true}/>
                        <EditButton basePath={prefPath} />
                    </Datagrid>
                </List>
                <AddOrganizationPreferenceButton id={params.id}/>
            </Tab>
        </TabbedShowLayout>
    )
};

export default OrganizationEdit;