import React from 'react';
import { Route } from 'react-router-dom';
import RoleEdit from "./role/RoleEdit";
import UserRoleEdit from "./role/UserRoleEdit";
import OrganizationEdit from "./organization/OrganizationEdit"
import PreferenceEdit from "./organization/PreferenceEdit"
import PreferenceCreate from "./organization/PreferenceCreate"
import OrganizationCreate from "./organization/OrganizationCreate";

export default [
    <Route exact path="/preference/create/:organization_id/" component={PreferenceCreate} />,
    <Route exact path="/application/:application_id/role/:id" component={RoleEdit} />,
    <Route exact path="/application/:application_id/user_role/:id" component={UserRoleEdit} />,
    <Route exact path="/organization/:organization_id/preference/:id" component={PreferenceEdit} />,
    <Route exact path="/organization/create" component={OrganizationCreate} />,
    <Route path="/organization/:id" component={OrganizationEdit} />
    ];