import {BooleanInput, Datagrid, EditButton, Filter, List, SearchInput, TextField, TextInput} from "react-admin";
import * as React from "react";

const FundFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn/>
    </Filter>
);

const FundList = props => (
    <List {...props} title="List of Funds" filters={<FundFilter />} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField label="Name" source="name" />
            <EditButton />
        </Datagrid>
    </List>
);

export default FundList;