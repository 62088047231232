import {Edit, Toolbar, SaveButton, DeleteButton, required, SimpleForm, TextInput, minLength} from "react-admin";
import ApplicationReferenceInput from "../ApplicationReferenceInput";
import * as React from "react";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => {
    const basePath = props.redirect;
    //const basePath = '/application';

    return (<Toolbar {...props} classes={useStyles()}>
        <SaveButton/>
        <DeleteButton redirect={basePath} undoable={false}/>
    </Toolbar>)
};


const RoleEdit = props => {
    // Read the post_id from the location
    const application_id = props.match.params.application_id;
    const id = props.match.params.id;

    //console.log('Role::create');
    const redirect = `/application/${application_id}`;
    const basePath = props.location.pathname;

    return (
        <Edit id={id} resource="role" basePath={basePath} {...props}>
            <SimpleForm
                redirect={redirect}
                toolbar={<CustomToolbar />}
            >
                <TextInput source="role" fullWidth required validate={minLength(5)} />
            </SimpleForm>
        </Edit>
    );
};

export default RoleEdit;