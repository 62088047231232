import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    Filter,
    TextInput,
    SearchInput,
    PasswordInput,
    EditButton,
    Create,
    Edit,
    SimpleForm,
    TabbedForm,
    FormTab,
    DateInput,
    DeleteButton, ReferenceManyField, required, BooleanInput, SelectInput, BooleanField
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';

import AddSystemRoleButton from "./user/AddSystemRoleButton";
import AddApplicationRoleButton from "./user/AddApplicationRoleButton";
import CountryReferenceInput from "./CountryReferenceInput";
import LanguageReferenceInput from "./LanguageReferenceInput";
import ApplicationReferenceInput from "./ApplicationReferenceInput";

const UserFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <TextInput label="Email" source="email" defaultValue="" />
        <TextInput label="Id" source="id" defaultValue=""/>
        <TextInput label="First Name" source="first_name" defaultValue="" />
        <TextInput label="Last Name" source="last_name" defaultValue="" />
        <TextInput label="Application" source="application" defaultValue="" />
        <BooleanInput label="Active" source="active" defaultValue="" />
    </Filter>
);

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <Box p="1em" fullWidth>
                <Box display="flex">
                    <Box flex={2} mr="1em">

                        <Typography variant="h6" gutterBottom>Identity</Typography>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput label="First Name" source="first_name" required fullWidth/>
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <TextInput label="Last Name" source="last_name" required fullWidth/>
                            </Box>
                        </Box>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput source="email" type="email" required fullWidth/>
                            </Box>

                            <Box flex={1} mr="0.5em">
                                <SelectInput fullWidth source="gender" choices={[
                                    { id: 1, name: 'Male' },
                                    { id: 2, name: 'Female' },
                                    { id: 3, name: 'Non-Binary' }
                                ]} />
                            </Box>
                        </Box>

                        <Box display="flex">
                            <Box flex={2} mr="0.5em">
                                <LanguageReferenceInput
                                    source="language"
                                    reference="language"
                                    validate={required()}
                                    perPage={10}
                                    fullWidth
                                    required
                                />
                            </Box>
                            <Box flex={2} mr="0.5em">
                                <DateInput source="date_of_birth" fullWidth />
                            </Box>
                        </Box>

                        <Box mt="1em" />

                        <Typography variant="h6" gutterBottom>Address</Typography>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput source="address" label="Street and apartment" fullWidth />
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <TextInput source="phone" label="Phone" fullWidth />
                            </Box>
                        </Box>

                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <CountryReferenceInput
                                    source="country"
                                    reference="country"
                                    validate={required()}
                                    perPage={10}
                                    fullWidth
                                    required
                                />
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <TextInput source="city" label="City" fullWidth />
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <TextInput source="state" label="State" fullWidth />
                            </Box>
                        </Box>
                    </Box>

                    <Box flex={1} ml="1em" fullWidth>

                        <Typography variant="h6" gutterBottom>Security</Typography>

                        <PasswordInput source="password" required fullWidth/>

                        <ApplicationReferenceInput
                            source="application_id"
                            reference="application"
                            validate={required()}
                            perPage={10}
                            fullWidth
                            required
                        />
                    </Box>

                </Box>
            </Box>
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => {
    let redirect = document.location.hash;
    let basePath = document.location.hash;

    redirect = redirect.replace('#', '');
    basePath = basePath.replace('#', '');
    basePath = basePath.substr(0, basePath.length - 2);

    return (<Edit {...props} undoable={false} >
        <TabbedForm>
            <FormTab label="General Information">
                <Box p="1em" fullWidth>
                    <Box display="flex">
                        <Box flex={2} mr="1em">

                            <Typography variant="h6" gutterBottom>Identity</Typography>

                            <Box display="flex">

                                <Box flex={1} mr="0.5em">
                                    <TextInput disabled label="Id" source="id" fullWidth/>
                                </Box>

                                <Box flex={1} mr="0.5em">
                                    <TextInput label="First Name" source="full_name.first_name" required fullWidth/>
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    <TextInput label="Last Name" source="full_name.last_name" required fullWidth/>
                                </Box>
                            </Box>

                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput source="email" type="email" required fullWidth/>
                                </Box>

                                <Box flex={1} mr="0.5em">
                                    <SelectInput fullWidth source="gender" choices={[
                                        { id: 1, name: 'Male' },
                                        { id: 2, name: 'Female' },
                                        { id: 3, name: 'Non-Binary' }
                                    ]} />
                                </Box>
                            </Box>

                            <Box display="flex">
                                <Box flex={2} mr="0.5em">
                                    <LanguageReferenceInput
                                        source="language"
                                        reference="language"
                                        validate={required()}
                                        perPage={10}
                                        fullWidth
                                        required
                                    />
                                </Box>
                                <Box flex={2} mr="0.5em">
                                    <DateInput source="date_of_birth" fullWidth />
                                </Box>
                            </Box>

                            <Box mt="1em" />

                            <Typography variant="h6" gutterBottom>Address</Typography>

                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput source="location.address" label="Street and apartment" fullWidth />
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <TextInput source="phone" label="Phone" fullWidth />
                                </Box>
                            </Box>

                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <CountryReferenceInput
                                        source="country"
                                        reference="country"
                                        validate={required()}
                                        perPage={10}
                                        fullWidth
                                        required
                                    />
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    <TextInput source="location.city" label="City" fullWidth />
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    <TextInput source="location.state" label="State" fullWidth />
                                </Box>
                            </Box>
                        </Box>

                        <Box flex={1} ml="1em" fullWidth>

                            <Typography variant="h6" gutterBottom>Security</Typography>

                            <PasswordInput source="password" fullWidth/>
                            <BooleanInput label="Active" source="active" fullWidth/>
                        </Box>

                    </Box>
                </Box>
            </FormTab>
            <FormTab label="System Roles">
                <ReferenceManyField
                    addLabel={false}
                    reference="user_global_role"
                    target="user"
                    sort={{field: "role", order: "ASC"}}
                >
                    <Datagrid>
                        <TextField source="id"/>
                        <TextField source="role"/>
                        <DeleteButton label="Demote" basePath={basePath} redirect={redirect}/>
                    </Datagrid>
                </ReferenceManyField>
                <AddSystemRoleButton />
            </FormTab>
            <FormTab label="Application-Specific Roles">
                <ReferenceManyField
                    addLabel={false}
                    reference="user_application_role"
                    target="user"
                    sort={{field: "role", order: "ASC"}}
                >
                    <Datagrid>
                        <TextField source="id"/>
                        <TextField source="application"/>
                        <TextField source="role"/>
                        <DeleteButton label="Demote" basePath={basePath} redirect={redirect}/>
                    </Datagrid>
                </ReferenceManyField>
                <AddApplicationRoleButton />

            </FormTab>
        </TabbedForm>
    </Edit>);
};


export const UserList = props => (
    <List {...props} title="List of users" filters={<UserFilter />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <EmailField source="email" />
            <TextField source="first_name"  />
            <TextField source="last_name" />
            <BooleanField label="Active"
                          source="active"
            />
            <TextField source="application" />
            <EditButton />
        </Datagrid>
    </List>
);