import {
    BooleanInput,
    Create,
    TextInput,
    SimpleForm,
    email,
    required
} from "react-admin";
import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';

import {Box, Typography} from "@material-ui/core";
import FundReferenceInput from "../FundReferenceInput";
import ApplicationReferenceInput from "../ApplicationReferenceInput";

const OrganizationCreate = props => {

    const params = props.match.params;

    return (
            <Create {...props} resource="organization" basePath="/organization" undoable={false}>
                <SimpleForm redirect={'/organization'} >

                    <Box p="1em" fullWidth>
                        <Box display="flex">
                            <Box flex={2} mr="1em">

                                <Typography variant="h6" gutterBottom>Basic Properties</Typography>

                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <TextInput label="Name" source="name" validate={required()} fullWidth/>
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextInput label="Domain" source="domain" fullWidth/>
                                    </Box>
                                </Box>

                                <Box mt="1em" />

                                <Typography variant="h6" gutterBottom>Contact</Typography>

                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <TextInput source="contact_email" type="email" validate={email()} label="Email" fullWidth />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextInput source="contact_full_name" label="Full Name" fullWidth />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextInput source="contact_phone" label="Phone" fullWidth />
                                    </Box>
                                </Box>
                            </Box>

                            <Box flex={1} ml="1em" fullWidth>
                                <Typography variant="h6" gutterBottom>Identification</Typography>

                                <ApplicationReferenceInput
                                    label="Application"
                                    allowEmpty={false}
                                    validate={required()}
                                    source="application_id"
                                    reference="application"
                                    perPage={10}
                                    fullWidth
                                />

                                <BooleanInput source="active" />

                                <FundReferenceInput
                                    label="Fund"
                                    allowEmpty={true}
                                    source="fund_id"
                                    reference="fund"
                                    perPage={10}
                                    fullWidth
                                />

                            </Box>

                        </Box>
                    </Box>

                </SimpleForm>
            </Create>
    )
};

export default OrganizationCreate;