import {
    NumberInput,
    Edit,
    TextInput,
    SimpleForm
} from "react-admin";
import * as React from "react";
import { ColorInput } from 'react-admin-color-input';
import BooleanNumInput from "../fields/BooleanNumInput";
import { numberPrefs, jsonPrefs, colorPrefs, htmlPrefs, booleanPrefs } from "./PreferenceTypes";

const stringField = (
    <TextInput source="value" fullWidth/>
);

const colorField = (
    <ColorInput source="value" picker="Sketch" />
);

const htmlField = (
    <TextInput multiline source="value" fullWidth/>
);

const boolField = (
    <BooleanNumInput source="value" label="Value"/>
);

const jsonField = (
    <TextInput multiline label="Value" source="value" fullWidth/>
);

const numberField = (
    <NumberInput source="value" label="Value" />
);

const PreferenceEdit = props => {

    const params = props.match.params;
    const orgPath = '/organization/' + params.organization_id;
    const prefPath = '/organization/' + params.organization_id + '/1';

    let fieldType = 'string';

    if(colorPrefs.indexOf(params.id) > -1) {
        fieldType = 'color';
    }

    if(htmlPrefs.indexOf(params.id) > -1) {
        fieldType = 'html';
    }

    if(booleanPrefs.indexOf(params.id) > -1) {
        fieldType = 'boolean';
    }

    if(jsonPrefs.indexOf(params.id) > -1) {
        fieldType = 'json';
    }

    if(numberPrefs.indexOf(params.id) > -1) {
        fieldType = 'number';
    }

    return (
        <Edit id={params.id}
              resource="organization_preferences"
              basePath={prefPath}
              undoable={false} {...props}>
            <SimpleForm redirect={prefPath}>
                <TextInput disabled label="Id" source="id" fullWidth/>
                { fieldType === 'string' ? stringField : ''}
                { fieldType === 'color' ? colorField : ''}
                { fieldType === 'html' ? htmlField : ''}
                { fieldType === 'boolean' ? boolField : ''}
                { fieldType === 'json' ? jsonField : ''}
                { fieldType === 'number' ? numberField : ''}
            </SimpleForm>
        </Edit>
    )
};

export default PreferenceEdit;