import {
    NumberInput,
    required,
    regex,
    FormDataConsumer,
    RadioButtonGroupInput,
    Create,
    TextInput,
    SimpleForm
} from "react-admin";
import * as React from "react";
import { ColorInput } from 'react-admin-color-input';
import BooleanNumInput from "../fields/BooleanNumInput";
import { numberPrefs, jsonPrefs, colorPrefs, htmlPrefs, booleanPrefs } from "./PreferenceTypes";

const PreferenceCreate = props => {

    const params = props.match.params;
    const orgPath = '/organization/' + params.organization_id;
    const prefPath = '/organization/' + params.organization_id + '/1';

    const typeField = (
        <RadioButtonGroupInput source="type" choices={[
            { id: 'string', name: 'String / HTML / JSON' },
            { id: 'color', name: 'HEX color code (#FFFFFF)' },
            { id: 'boolean', name: 'Boolean variable' },
            { id: 'number', name: 'Number field' },
        ]} value="string"/>
    )
    const stringField = (
        <TextInput name="value" source="value" label="Value" multiline fullWidth />
    );

    const colorField = (
        <ColorInput name="value" source="value" label="Value" picker="Sketch" />
    );

    const htmlField = (
        <TextInput multiline source="value" label="Value" name="value" fullWidth />
    );

    const boolField = (
        <BooleanNumInput name="value" label="Value" source="value" />
    );

    const jsonField = (
        <TextInput multiline label="Value" name="value"  source="value" fullWidth/>
    );

    const numberField = (
        <NumberInput name="value" label="Value" source="value" />
    );

    return (
        <Create
              resource="organization_preferences"
              basePath={prefPath}
              {...props}>
            <SimpleForm redirect={prefPath}>
                <TextInput name="id" label="Id"
                           fullWidth validate={regex(/^[_a-z0-9]+$/, 'Should contain only latin lowercase characters, numbers and underscores')
                } required/>

                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                            if(
                                colorPrefs.indexOf(formData.id) === -1 &&
                                htmlPrefs.indexOf(formData.id) === -1 &&
                                booleanPrefs.indexOf(formData.id) === -1 &&
                                jsonPrefs.indexOf(formData.id) === -1 &&
                                numberPrefs.indexOf(formData.id) === -1
                                ) {
                                return typeField;
                            }
                        }
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                            if(colorPrefs.indexOf(formData.id) > -1 || ( colorPrefs.indexOf(formData.id) === -1 && formData.type === 'color')) {
                                return colorField;
                            }

                            if(htmlPrefs.indexOf(formData.id) > -1 || ( htmlPrefs.indexOf(formData.id) === -1 && formData.type === 'string')) {
                                return htmlField;
                            }

                            if(booleanPrefs.indexOf(formData.id) > -1 || ( booleanPrefs.indexOf(formData.id) === -1  && formData.type === 'boolean')) {
                                return boolField;
                            }

                            if(jsonPrefs.indexOf(formData.id) > -1 || ( jsonPrefs.indexOf(formData.id) === -1 && formData.type === 'string')) {
                                return jsonField;
                            }

                            if(numberPrefs.indexOf(formData.id) > -1 || ( numberPrefs.indexOf(formData.id) === -1 &&  formData.type === 'number')) {
                                return numberField;
                            }

                            return  stringField;
                        }
                    }
                </FormDataConsumer>

            </SimpleForm>
        </Create>
    )
};

export default PreferenceCreate;