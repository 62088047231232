import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    SearchInput,
    EditButton,
    Create,
    Edit,
    SimpleForm,
    TabbedForm,
    FormTab,
    ReferenceManyField,
    BooleanInput,
    BooleanField,
    Button
} from 'react-admin';

const ApplicationFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn/>
        <TextInput label="Name" source="name" defaultValue="" />
        <TextInput label="Domain" source="domain" defaultValue=""/>
        <TextInput label="Secret" source="secret" defaultValue="" />
        <BooleanInput label="Active" source="active" defaultValue="" />
    </Filter>
);



export const ApplicationCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" defaultValue="" fullWidth/>
            <TextInput label="Domain" source="domain" defaultValue="" fullWidth/>
            <TextInput label="Return To URI" source="return_to" fullWidth />
            <TextInput label="Login URI" source="login_url" fullWidth/>
            <TextInput label="Integration URI" source="integration_url" fullWidth/>
        </SimpleForm>
    </Create>
);



export const ApplicationList = props => (
    <List {...props} title="List of Applications" filters={<ApplicationFilter />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Domain" source="domain"  />
            <TextField label="Secret Key" source="secret" />
            <BooleanField label="Active"
                          source="active"
            />
            <TextField label="Return To URI" source="return_to" />
            <TextField label="Login URI" source="login_url" />
            <EditButton />
        </Datagrid>
    </List>
);