import {Create, required, SimpleForm, ReferenceInput, SelectInput,FormDataConsumer} from "react-admin";
import UserReferenceInput from "../UserReferenceInput";
import * as React from "react";
import { useLocation } from 'react-router';

const AddApplicationRoleToUser = props => {
    // Read the post_id from the location
    const location = useLocation();
    let user_id =
        location.state && location.state.record
            ? location.state.record.user_id
            : undefined;

    if(user_id === undefined) {
        user_id = location.pathname;
        user_id = user_id.replace('/user_application_role/create/', '');
        user_id = user_id.replace('/', '');
    }

    //console.log('Role::create');
    const redirect = user_id ? `/user/${user_id}/2` : false;

    return (
        <Create {...props} title="Assign new Application Role for User">
            <SimpleForm
                redirect={redirect}
            >
                <UserReferenceInput
                    source="user"
                    reference="user"
                    validate={required()}
                    defaultValue={user_id}
                    perPage={10}
                    fullWidth
                    required
                />
                <ReferenceInput label="Application" source="application" reference="application" fullWidth>
                    <SelectInput optionText="name"  fullWidth required/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                            if( formData.application) {
                                return (
                                    <ReferenceInput label="Application Role"
                                                    source="application_role_id"
                                                    reference="application_role"
                                                    perPage={100}
                                                    filter={{application_id: formData.application}}
                                                    sort={{field: 'role', order: 'ASC'}}
                                                    fullWidth
                                                    required
                                                    {...rest}
                                    >
                                        <SelectInput optionText="role" fullWidth required allowEmpty={false}/>
                                    </ReferenceInput>
                                );
                            }
                        }
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};

export default AddApplicationRoleToUser;