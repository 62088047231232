import ApiProvider from './apiProvier';

let api = new ApiProvider();

export default {

    login:  ({ username, password }) =>  {
        return new Promise(function(resolve, reject){

            api.post('/login/', { email: username, password: password})
                .then(response => {
                    if(typeof(response.token) !== 'undefined') {
                        localStorage.setItem('token', response.token);
                        return resolve(response.token);
                    }

                    return reject('Please, try again later');
                }, error => {
                    return reject(error);
                });
        }).then(
            (token) => {
                return new Promise(function(resolve, reject){

                    api.get('/users/me/', null, token)
                        .then(response => {
                            if(typeof(response.id) === 'undefined') {
                                return reject('Unable to fetch profile info');
                            }
                            localStorage.setItem('user', JSON.stringify(response));
                            return resolve(response);
                        }, error => {
                            return reject(error);
                        });
                })
            },
            (error) => {
                if(error === 'Unauthorized') {
                    error = 'Unable to log You in, please check credentials';
                }
                throw new Error(error);
            }
        );
    },

    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('user')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
};