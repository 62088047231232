export default class {

    METHOD_GET = 'GET';
    METHOD_POST = 'POST';
    METHOD_DELETE = 'DELETE';
    METHOD_PUT = 'PUT';
    METHOD_PATCH = 'PATCH';

    apiUrl = (apiMethod) => {
        return process.env.REACT_APP_API_URL + apiMethod;
    }

    doRequest = async(httpMethod, apiMethod, data, token) => {

        let headers = { 'Content-Type': 'application/json' };
        if( typeof(token) !== 'undefined' ) {
            headers['Authorization'] = 'Bearer ' + token;
        }

        const request = new Request(this.apiUrl(apiMethod), {
            method: httpMethod,
            body: typeof(data) !== 'undefined' && data ? JSON.stringify(data) : null,
            headers: new Headers(headers),
        });

        const response = await fetch(request);

        if(httpMethod === this.METHOD_DELETE) {
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject(response.statusText);
            }
            return Promise.resolve();
        }

        let json = undefined;

        try {
            json = await response.json();
        } catch(e){}

        let errorText = response.statusText;

        if(response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            return Promise.reject('Your session has expired');
        }

        if(typeof(json) !== 'undefined' && json) {
            if (typeof (json.error) !== 'undefined') {
                errorText = json.error;
            }

            if (typeof (json.message) !== 'undefined') {
                errorText = json.message;
            }
        }

        if (response.status < 200 || response.status >= 300) {
            return Promise.reject(errorText);
        }

        return Promise.resolve(json);
    }

    get = (apiMethod, data, token) => {
        return this.doRequest(this.METHOD_GET, apiMethod, data, token);
    }

    post = (apiMethod, data, token) => {
        return this.doRequest(this.METHOD_POST, apiMethod, data, token);
    }

    delete = (apiMethod, data, token) => {
        return this.doRequest(this.METHOD_DELETE, apiMethod, data, token);
    }

    patch = (apiMethod, data, token) => {
        return this.doRequest(this.METHOD_PATCH, apiMethod, data, token);
    }

    put = (apiMethod, data, token) => {
        return this.doRequest(this.METHOD_PUT, apiMethod, data, token);
    }
}

