import {
    Edit,
    SimpleForm,
    TextInput,
    regex,
    required,
    TabbedShowLayout,
    Tab,
    List,
    Datagrid,
    TextField, BooleanField, EditButton, Filter, SearchInput, BooleanInput
} from "react-admin";
import * as React from "react";
import FundList from "./FundList";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddRoleButton from "../role/AddRoleButton";
import AddOrganizationButton from "../organization/AddOrganizationButton";

const OrganizationsFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn/>
        <TextInput label="Name" source="name" defaultValue="" />
        <TextInput label="Domain" source="domain" defaultValue=""/>
        <TextInput label="Application" source="application" defaultValue=""/>
        <TextInput label="Import Id" source="import_id" defaultValue=""/>
        <BooleanInput label="Active" source="active" defaultValue="" />
    </Filter>
);

const FundEdit = props => {

    /**
    const organizationPath = '/organization';

    return (
        <Edit
            id={props.id}
            undoable={false}
            {...props}>
            <SimpleForm redirect="/fund/">
                <TextInput name="id" label="Id"
                           fullWidth disabled/>
                <TextInput source="name" fullWidth required />
            </SimpleForm>
        </Edit>
    );*/
    const appPath = '/application/' + props.id;
    const basePath = '/fund';
    const userPath = '/application/' + props.id + '/user_role';
    const organizationPath = '/organization';

    return (
        <TabbedShowLayout resource="fund" >
            <Tab label="General Info" resource="fund">
                <Edit id={props.id} resource="fund" undoable={false} basePath={basePath} {...props}>
                    <SimpleForm redirect='/fund'>
                        <TextInput name="id" label="Id"
                                   fullWidth disabled/>
                        <TextInput source="name" fullWidth required />
                    </SimpleForm>
                </Edit>
            </Tab>
            <Tab label="Organizations">

                <List
                    hasList={false}
                    resource="organizaion"
                    filters={<OrganizationsFilter />} bulkActionButtons={false}
                    basePath={basePath} hasCreate={false} hasEdit={false} hasShow={false} exporter={false}
                    filter={{fund_id: props.id, fund_organizations: true}}
                >
                    <Datagrid>
                        <TextField source="id" sortable={true}/>
                        <TextField source="import_id" label="Import Id" sortable={true}/>
                        <TextField source="description.domain" label="Domain" sortable={true}/>
                        <TextField source="description.name" label="Name" sortable={true}/>
                        <TextField source="application" label="Application" sortable={true}/>
                        <BooleanField label="Active"
                                      source="active"
                                      sortable={true}
                        />
                        <EditButton basePath={organizationPath} />
                    </Datagrid>
                </List>
            </Tab>
        </TabbedShowLayout>
    );
};

export default FundEdit;