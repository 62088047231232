import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

import RoleCreate from './RoleCreate';
import RoleEdit from "./RoleEdit";

export default {
    create: RoleCreate,
    edit: RoleEdit,
    icon: ChatBubbleIcon
};
