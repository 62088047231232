import React from 'react';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
    button: {
        marginTop: '10px'
    }
};

const AddApplicationRoleButton = ({ classes, record }) => {
    return (
        <Button
            variant="contained"
            component={Link}
            to={`/user_application_role/create/${record.id}`}
            label="Add Application Role"
            className={classes.button}
        >
        <AddCircleOutlineIcon/>
    </Button>
    );
};

export default withStyles(styles)(AddApplicationRoleButton);